@use "mixins";
@use 'global/bases';

.l-page {
	$c: &;
	max-width: var(--page-width);
	margin: 0 auto;
	display: grid;
	grid-template-areas:
		"aside content ."
		"cta cta cta";
	grid-template-columns: 1fr var(--page-width) 1fr;

	&__aside {
		grid-area: aside;
	}
	&__content {
		grid-area: content;
	}

	&__cta {
		grid-area: cta;
		position: relative;

		&-text {
			color: white;
			position: absolute;
			text-align: center;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;
		}
	}

	&__section {
		padding: var(--page-section-padding) 0;

		&-title {
			@include bases.title;
			margin-bottom: var(--p-rhythm);
		}
	}

	&--fullwidth-content {
		grid-template-areas:
			"content content content"
			"cta cta cta";
		max-width: none;
		#{$c}__section {
			padding-left: var(--page-gutter);
			padding-right: var(--page-gutter);
		}
	}
}
