@use 'constants';
@use 'mixins';
@use 'global/bases';

.l-home-fold {
	padding: var(--p-rhythm-lg) var(--page-gutter);
	&__tagline {
		@include bases.title;
		margin-bottom: 1em;
	}

	+ .l-page__section.l-page__section--blue-bg {
		margin-top: calc(-1 * var(--page-section-padding));
	}
}
