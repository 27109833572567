.card {
	display: block;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	&__image {
	}

	&__details {
		padding: var(--p-rhythm-sm);
		background: white;
	}

	&__title {
	}
	&__subtitle {
	}

	&:hover {
	}
}
