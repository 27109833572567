.social-bar {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	&__item + &__item {
		margin-left: 20px;
	}
	&__link {
		display: block;
		color: var(--t-text-color);
		svg {
			width: auto;
			height: 32px;
			display: block;
		}
	}
}
