@use 'constants';
@use 'mixins';
@use 'global/bases';

.content-cta {
	&__copy {
		padding: var(--p-rhythm);
		&-title {
			font-size: var(--fs-heading);
		}
		&-summary {
			margin-top: var(--p-rhythm-sm);
		}
	}
}

@include mixins.to-md {
	.content-cta {
		display: flex;
		flex-direction: column-reverse;
		&__copy {
			&-title {
				margin: 1.5em 0 1em 0;
			}
		}
	}
}

@include mixins.from-md {
	.content-cta {
		$c: &;
		display: grid;
		grid-template-areas: "copy cover";
		grid-template-columns: 1fr 1fr;
		gap: var(--p-rhythm);
		&__copy {
			display: flex;
			flex-direction: column;
			justify-content: center;
			grid-area: copy;
			&-title {
				margin-bottom: 1em;
			}
		}
		&__cover {
			grid-area: cover;
			margin: auto 0;
		}

		&--reverse {
			#{$c}__copy {
				grid-column: 2;
			}
			#{$c}__cover {
				grid-column: 1;
			}
		}
	}
}
