@use "mixins";

.form-field {
	position: relative;
	width: 100%;

	&__field,
	&__label {
		display: block;
		line-height: 1.3;
		background: none;
	}

	&__field {
		border: 0 none;
		padding: var(--p-rhythm-sm);
		width: 100%;
		appearance: none;
		border-radius: 2px;
		border: 1px solid black;
		color: black;
	}

	&__boolean-group {
		> * + * {
			margin-top: calc(var(--p-rhythm-sm) / 2);
		}
	}

	&__label {
		margin-bottom: 12px;
		&-required {
			color: red;
		}
	}

	&__error-messages {
		color: red;
		width: 100%;
		&-single {
			margin-top: 10px;
		}
	}

	&__help {
		width: 100%;
	}
}

@include mixins.from-md {
	.form-field {
		font-size: 12px;
	}
}
