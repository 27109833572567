@use "constants";
@use "mixins";
@use "global/bases";

.submenu {
	&__links {
		padding: var(--page-gutter);
	}
}

@include mixins.to-md {
	.submenu {
		transition: transform 400ms ease-in-out;
		height: 100vh;
		width: 100%;

		position: fixed;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		transform: translateX(100%);
		overflow-y: scroll;
		z-index: 10;

		.orientation-changing & {
			transition: none;
		}

		&.is-active {
			transform: translateX(0);
		}

		&__back {
			color: var(--theme-text-color);
			svg {
				width: 12px;
				height: auto;
				margin-right: 10px;
				margin-bottom: 2px;
				margin-left: -2px;
				transform: rotate(-270deg);
			}
		}

		&__image {
			display: none;
		}
		&__links {
			font-size: calc(var(--fs-body) * 1.5);
			li + li {
				margin-top: calc(var(--p-rythm) / 3);
			}
		}

		&__column {
			margin-top: var(--p-rythm);
		}
	}
}

@include mixins.from-md {
	.submenu {
		padding-top: var(--p-rhythm-sm);
		position: relative;
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			width: 0;
			height: 0;
			border-left: var(--p-rhythm-sm) solid transparent;
			border-right: var(--p-rhythm-sm) solid transparent;
			border-bottom: var(--p-rhythm-sm) solid rgba(black, 0.05);
		}
		&__links {
			padding: var(--p-rhythm-sm);
			background: rgba(black, 0.05);
			&-single + &-single {
				margin-top: calc(var(--p-rhythm-sm) / 2);
			}
			a {
				@include bases.base-link;
			}
		}

		&__back {
			display: none;
		}
	}
}
