@use 'mixins';
@use 'global/bases';

.logo-flop {
}

@include mixins.from-md {
	.logo-flop {
		display: flex;
		width: 100%;
		justify-content: space-between;
		img {
			width: auto;
			height: 135px;
		}
		img + img {
			margin-left: var(--p-rhythm);
		}
	}
}
