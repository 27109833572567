@use "sass:math";

@use "constants";
@use "mixins";

@function strip-units($number) {
	// Strips units from string e.g. 15px -> 15
	@return math.div($number, $number * 0 + 1);
}

:root {
	--c-blue-dark: #030b13;
	--c-orange: fuchsia;
	--c-blue: blue;

	--t-text-color: #000000;
	--t-accent-color: var(--c-blue);
	--t-accent-hover-color: var(--c-orange);
	--t-page-bg-color: white;

	--header-height: 137px;

	--p-rhythm: 32px;
	--p-rhythm-sm: calc(var(--p-rhythm) / 2);
	--p-rhythm-lg: calc(var(--p-rhythm) * 2);
	--page-width: min(calc(100vw - var(--p-rhythm) * 2), 1300px);
	--page-gutter: calc((100vw - var(--page-width)) / 2);
	--page-section-padding: calc(var(--page-gutter) / 4);

	--fs-body: 16px;
	--fs-big-title: 30px;
	--fs-title: 38px;
	--fs-subtitle: 20px;
}
