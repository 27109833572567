@use "sass:math";

@mixin on-circle($item-count, $circle-size, $item-size) {
	position: relative;
	width: $circle-size;
	height: $circle-size;
	padding: 0;
	border-radius: 50%;
	list-style: none;

	> * {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		width: $item-size;
		height: $item-size;
		margin: -(math.div($item-size, 2));

		$angle: (math.div(360, $item-count));
		$rot: 0;

		@for $i from 1 through $item-count {
			&:nth-of-type(#{$i}) {
				transform: rotate($rot * 1deg)
					translate(math.div($circle-size, 2))
					rotate($rot * -1deg);
			}

			$rot: $rot + $angle;
		}
	}
}

.l-project-list {
	padding: 0;
	margin: 0;
	list-style: none;
	width: 100%;

	th {
		text-align: left;
	}

	td,
	th {
		padding: 0.5em;
	}

	&__item {
		// display: flex;
		// align-items: center;
		// margin-bottom: var(--p-rhythm);
		// &:last-child {
		// 	margin-bottom: 0;
		// }
		&__title {
			// font-size: 1.8em;
		}

		// > * + * {
		// 	margin-left: var(--p-rhythm);
		// }
	}
	&__featured-image {
		width: 100px;
		height: 100px;
		border-radius: 100%;
		overflow: hidden;
		display: block;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&__palette-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 100px;
	}
	&__palette {
		@include on-circle($item-count: 5, $circle-size: 80px, $item-size: 20px);

		&__item {
			width: 20px;
			height: 20px;
			border-radius: 100%;
			// margin: 5px;
		}
	}
	&__sattelite {
		overflow: hidden;
		width: 100px;
		height: 100px;
		position: relative;
		border-radius: 100%;
		img {
			position: absolute;
			top: -10px;
			bottom: -10px;
			right: -10px;
			left: -10px;
		}
	}
}
