@use "mixins";

.l-site-footer {
	max-width: var(--page-width);
	margin: var(--p-rhythm) auto;

	&__colophon {
		display: flex;
		justify-content: space-between;
	}

	&__logo {
		display: block;
		height: 32px;
		img {
			width: auto;
			height: 100%;
		}
	}
	&__copyright {
		margin-top: var(--p-rhythm);
	}
}
