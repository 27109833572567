@use 'mixins';
@use "global/bases";

.accordion {
    &--item {
        $i: &;
        

        &:not(:first-child) {
            border-top: 2px solid var(--t-text-color);
        }

		&.is-active {
			#{$i}-trigger {
				svg {
					transform: rotate(-90deg);
				}
			}
		}

		&-trigger {

            @include bases.base-heading;
			padding: var(--p-rhythm) 0;
            display: block;
			appearance: none;
			outline: none !important;
			position: relative;
			font-size: var(--fs-subtitle);
			font-weight: 900;

			padding-right: 80px;

			&-icon {
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				right: 0;
				top: 50%;
				width: 30px;
				height: 30px;
				transform: translateY(-50%);
				transition: background-color 150ms ease-in-out;
				svg {
					transform: rotate(90deg);
					color: var(--white);
					width: 45px;
					height: auto;
					position: absolute;
					transition: all 150ms ease-in-out;
				}

				&:hover {
					svg {
						color: var(--orange);
					}
				}
			}

			&:hover {
				cursor: pointer;
			}
		}
		&-content {
			overflow: hidden;
            transition: height 250ms ease-out;
			height: var(--accordion-height);
            padding-bottom: calc(var(--p-rhythm) * 1.5);

			&.is-closed {
				height: 0;
				padding: 0;
			}
		}
    }

    @include mixins.from-md {
        &--item {
			&-trigger {
				&-icon {
					width: 40px;
					height: 40px;
					svg {
						width: 60px;
					}
				}
            }
        }
    }
}
