@use 'constants';
@use 'mixins';
@use 'global/bases';

@forward 'fonts';

html {
	width: 100%;
	overflow-x: hidden;
}

body {
	@include bases.body-font;
	font-size: 16px; // Changing this will impact rem based calculations
	line-height: 1.4;
	color: var(--t-text-color);
	margin: 0;

	* {
		font-size: var(--fs-body);
	}

	&.preload {
		* {
			transition: none !important;
		}
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include bases.base-heading;
}

.lede {
	font-size: 1.2em;
}

.richtext,
.rich-text {
	> * + * {
		margin-top: 0.4em;
	}
	> * + h1,
	> * + h2,
	> p + p {
		margin-top: 1em;
	}
	> *:first-child {
		margin-top: 0;
	}
	> *:last-child {
		margin-bottom: 0;
	}

	a:not([class]) {
		@include bases.base-link;
		display: inline;
		@include bases.base-link-hover;
	}

	ul,
	ol {
		padding-left: 1em;
	}

	ul {
		li {
			list-style: disc;
		}
	}

	table {
		tr {
			border-bottom: 1px solid;
			&:last-child {
				border-bottom: 0 none;
			}
		}
		th,
		td {
			vertical-align: top;
			text-align: left;
		}
		th {
			font-weight: normal;
			padding-bottom: 0.2em;
			padding-right: 0.4em;
		}
		td {
			padding-top: 0.1em;
			padding-bottom: 0.2em;
			padding-right: 0.4em;
		}
	}

	&-image {
		position: relative;

		&.full-width {
			width: 100%;
			height: auto;
		}
	}
}

@include mixins.from-md {
	.richtext,
	.rich-text {
		> * + * {
			margin-top: 1em;
		}
		*:first-child {
			margin-top: 0;
		}
		*:last-child {
			margin-bottom: 0;
		}

		&-image {
			&.left,
			&.right {
				max-width: 50%;
				display: block;
			}
			&.right {
				margin-left: auto;
			}
		}
	}
}
