.l-banner {
	&__background {
		width: 100%;
		height: auto;
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
}
