@font-face {
	font-family: "proxima-nova";
	src: url("../fonts/proxima-nova-normal-700.woff2") format("woff2"),
		url("../fonts/proxima-nova-normal-700.woff") format("woff"),
		url("../fonts/proxima-nova-normal-700.ttc") format("opentype");
	font-display: swap;
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: "proxima-nova";
	src: url("../fonts/proxima-nova-normal-600.woff2") format("woff2"),
		url("../fonts/proxima-nova-normal-600.woff") format("woff"),
		url("../fonts/proxima-nova-normal-600.ttc") format("opentype");
	font-display: swap;
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: "proxima-nova";
	src: url("../fonts/proxima-nova-normal-400.woff2") format("woff2"),
		url("../fonts/proxima-nova-normal-400.woff") format("woff"),
		url("../fonts/proxima-nova-normal-400.ttc") format("opentype");
	font-display: swap;
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "proxima-nova";
	src: url("../fonts/proxima-nova-normal-500.woff2") format("woff2"),
		url("../fonts/proxima-nova-normal-500.woff") format("woff"),
		url("../fonts/proxima-nova-normal-500.ttc") format("opentype");
	font-display: swap;
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: "monarcha";
	src: url("../fonts/monarcha-normal-300.woff2") format("woff2"),
		url("../fonts/monarcha-normal-300.woff") format("woff"),
		url("../fonts/monarcha-normal-300.ttc") format("opentype");
	font-display: swap;
	font-style: normal;
	font-weight: 300;
}
