@use "constants";
@use "mixins";

.l-site-header {
	position: relative;
	z-index: 100;
	padding: 0 var(--page-gutter);
	&--inner {
		padding-top: var(--p-rhythm);
	}
	&__logo {
		display: block;
		height: 32px;
		img {
			width: auto;
			height: 100%;
		}
	}

	&__menu-trigger {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 3;
	}

	.l-navigation {
		z-index: 2;
	}
}

@include mixins.from-md {
	.l-site-header {
		margin-top: 0;
		&--inner {
			display: flex;
			justify-content: space-between;
			max-width: var(--page-width);
			margin: 0 auto;
		}

		&__logo {
			width: 150px;
			height: auto;
			img {
				width: 100%;
				height: auto;
			}
		}

		&__menu-trigger {
			display: none;
		}
	}
}
